import React from "react"

import { CartList } from "./List/CartList"
import { CartPayment } from "./Payment/CartPayment"
import { withCart } from "./withCart"
import { Wrapper, Title, Main, Mobile, Desktop, Loading } from "./CartStyles"
import { CartEmpty } from "./Empty/CartEmpty"
import { Related } from "./Related/Related"

export const Cart = withCart(
  ({ title, count, loading }): JSX.Element => (
    <Wrapper>
      <Title>{title}</Title>
      {loading ? (
        <Loading>Loading...</Loading>
      ) : count && count > 0 ? (
        <>
          <Main>
            <CartList />
            <Mobile>
              <Related />
            </Mobile>
            <CartPayment />
          </Main>
          <Desktop>
            <Related />
          </Desktop>
        </>
      ) : (
        <CartEmpty />
      )}
    </Wrapper>
  )
)
