import React from "react"

import { useShopifyVariants } from "../../../../hooks/useShopify"
import { useCart } from "../../../../hooks/useCart"
import { useApp } from "../../../../hooks/useApp"

export const withCard = Component => ({ name = "Card", item }) => {
  const { localActiveVariant: activeVariant, selectedOptions, handleVariant } = useShopifyVariants({
    product: item,
    firstAvailable: true,
  })

  const {
    config: {
      settings: {
        constraints: { SIZE },
      },
    },
  } = useApp()

  const { addToCart, loading } = useCart()

  const handleAddToCart = () => addToCart(item, activeVariant)

  Component.displayName = name
  return (
    <Component
      handleAddToCart={handleAddToCart}
      loading={loading}
      activeVariant={activeVariant}
      selectedOptions={selectedOptions}
      handleVariant={handleVariant}
      SIZE={SIZE}
      {...item}
    />
  )
}
