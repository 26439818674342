import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useCartContext } from "../../../providers/cart"

export const withCartList = Component => ({ name = "CartList" }: any) => {
  const { cart:cartContext } = useCartContext()

  const { cart } = useStaticQuery(graphql`
    query SANITY_PAGE_CART_LIST {
      cart: sanityPageCart {
        additionalLineItemsListTitle
        additionalLineItemsTotalText
      }
    }
  `)

  const { additionalLineItemsListTitle, additionalLineItemsTotalText } = cart || {}

  let count = 0

  cartContext?.lines?.map((item: any) => (item?.merchandise?.product?.title?.toLowerCase().includes("gift card") ? (count += 1) : (count += item?.quantity)))

  Component.displayName = name
  return (
    <Component
      lineItems={cartContext?.lines}
      count={count}
      additionalLineItemsListTitle={additionalLineItemsListTitle}
      additionalLineItemsTotalText={additionalLineItemsTotalText}
    />
  )
}
