import React from "react"
import { useCartContext } from "../../providers/cart"

export const withCart = Component => ({ name = "Cart", page }) => {
  const { title } = page
  const { count, initializedLoading: loading } = useCartContext()

  Component.displayName = name
  return <Component title={title} count={count} loading={loading} />
}
